import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';

interface Account {
    id: string;
    name: string;
  }

@Component({
  selector: 'app-facebook-ad-account-select-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  templateUrl: './facebook-ad-account-select-dialog.component.html',
})

export class FacebookAdAccountSelectDialogComponent {
  form: FormGroup;
  filteredAccounts: Account[] = [];

  constructor(
    private dialogRef: MatDialogRef<FacebookAdAccountSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      selectedAdAccount: [null]
    });

    this.filteredAccounts = this.data.accounts;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect(): void {
    this.dialogRef.close(this.filteredAccounts.find(account => account.id === this.form.value.selectedAdAccount));
  }
}
