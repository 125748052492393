import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Brand } from '../models/brand.class';
import { BrandService } from './brand.service';

@Injectable({
  providedIn: 'root'
})
export class BrandSelectionService {
  // Storage key for localStorage
  private readonly STORAGE_KEY = 'ms_selected_brand';

  // BehaviorSubject to track the currently selected brand
  private selectedBrandSubject = new BehaviorSubject<Brand | null>(null);

  // Subject to notify when brand list should be refreshed
  private brandListUpdateSubject = new Subject<void>();

  // Observable that components can subscribe to
  public selectedBrand$ = this.selectedBrandSubject.asObservable();

  // Observable for brand list updates
  public brandListUpdate$ = this.brandListUpdateSubject.asObservable();

  constructor(private brandService: BrandService) {
    // Check localStorage for previously selected brand on initialization
    this.initializeFromStorage();
  }

  /**
   * Sets the currently selected brand and persists it to localStorage
   */
  public selectBrand(brand: Brand | null): void {
    this.selectedBrandSubject.next(brand);
    this.persistToStorage(brand);
  }

  /**
   * Selects a brand by its ID by fetching it from BrandService
   * @param brandId The ID of the brand to select
   */
  public selectBrandById(brandId: string): void {
    this.brandService.getById(brandId).subscribe({
      next: (brand: Brand) => {
        this.selectBrand(brand);
      },
      error: (error) => {
        console.error('Error fetching brand by ID:', error);
        // If brand cannot be found, default to "All Brands"
        this.selectAllBrands();
      }
    });
  }

  /**
   * Gets the currently selected brand synchronously
   */
  public getSelectedBrand(): Brand | null {
    return this.selectedBrandSubject.getValue();
  }

  /**
   * Gets the ID of the currently selected brand or empty string for "All Brands"
   * Convenience method for components that just need the ID
   */
  public getSelectedBrandId(): string {
    const brand = this.selectedBrandSubject.getValue();
    return brand?.id || '';
  }

  /**
   * Checks if a specific brand is currently selected
   */
  public isBrandSelected(brandId?: string): boolean {
    const selectedBrand = this.selectedBrandSubject.getValue();

    if (!selectedBrand) {
      return false; // No brand is selected
    }

    if (!brandId) {
      return true; // Any brand is selected
    }

    return selectedBrand.id === brandId;
  }

  /**
   * Checks if "All Brands" is selected (no specific brand)
   */
  public isAllBrandsSelected(): boolean {
    return this.selectedBrandSubject.getValue() === null;
  }

  /**
   * Selects "All Brands" (clears the selected brand)
   */
  public selectAllBrands(): void {
    this.selectBrand(null);
  }

  /**
   * Notifies subscribers that brand list should be refreshed
   * Use this when brands are created, updated, or deleted
   */
  public notifyBrandListChange(): void {
    this.brandListUpdateSubject.next();
  }

  /**
   * Sets default brand based on loaded brands
   * Only restores a previously selected brand from localStorage if it exists
   * and is still in the available brands list
   */
  public initializeWithDefaultBrand(brands: Brand[]): void {
    if (!brands || brands.length === 0) {
      return;
    }

    // Check if we already have a brand from localStorage
    const currentBrand = this.getSelectedBrand();

    if (currentBrand !== null) {
      // If we have a stored brand, check if it's still in the brands list
      const storedBrandStillExists = brands.some(brand => brand.id === currentBrand.id);

      if (storedBrandStillExists) {
        // Brand exists in the list, so keep using it
        return;
      } else {
        // Selected brand no longer exists in available brands
        // Default to "All Brands" (null) instead of forcing selection of first brand
        this.selectAllBrands();
      }
    }

    // If currentBrand is null, that means "All Brands" is selected
    // or there was no selection in localStorage, so just leave it as is
    // DO NOT default to the first brand
  }

  /**
   * Persists the selected brand to localStorage
   * @param brand The brand to persist, or null for "All Brands"
   */
  private persistToStorage(brand: Brand | null): void {
    if (brand) {
      // Store brand data as a JSON string
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(brand));
    } else {
      // Remove stored brand when "All Brands" is selected
      localStorage.removeItem(this.STORAGE_KEY);
    }
  }

  /**
   * Initializes the brand selection from localStorage
   */
  private initializeFromStorage(): void {
    const storedBrand = localStorage.getItem(this.STORAGE_KEY);
    if (storedBrand) {
      const brand = JSON.parse(storedBrand) as Brand;
      this.selectedBrandSubject.next(brand);
    } else {
      // Explicitly set to null if no brand is stored
      // This represents "All Brands" selected
      this.selectedBrandSubject.next(null);
    }
  }
}
