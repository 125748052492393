import { BrandLight } from './brand-light.class';

export class SocialMediaConnection {
  public id: string = '';
  public name: string | null = null;
  public socialNetwork: string = '';
  public healthStatus: HealthStatus | null = null;
  public tokenExpiryDate: string = '';
  public createdOn: string = '';
  public updatedOn: string = '';
  public url: string | null = null;
  public brands?: BrandLight[];
  public facebookConnection?: any;
  public instagramConnection?: any;
}

export enum HealthStatus {
  HEALTHY = 'HEALTHY',
  TOKEN_ABOUT_TO_EXPIRE = 'TOKEN_ABOUT_TO_EXPIRE',
  TOKEN_INVALID = 'TOKEN_INVALID',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  DISCONNECTED = 'DISCONNECTED',
}
