import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageComponent } from '../../image/image.component';
import { BrandLight } from '../../../models/brand-light.class';
import { StringUtils } from '../../../utils/string-utils';
import { ExpiryObject } from '../../../../modules/main/mad-social/brands/brands-table/brands-table.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SocialMediaConnection } from '../../../models/social-media-connection.class';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'brand-card',
  standalone: true,
  imports: [
    CommonModule,
    ImageComponent,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './brand-card.component.html',
  styleUrl: './brand-card.component.scss',
})
export class BrandCardComponent {
  @Input() brand!: BrandLight;
  @Input() expiry: ExpiryObject | null = null;
  @Output() clickCardEvent = new EventEmitter<void>();
  @Output() clickTitleEvent = new EventEmitter<void>();

  public brandColor: string = '#C0E9A7';
  public lightColor: string = '#e0ffc7';
  public gradient: string = `linear-gradient(#C0E9A7, white 30%)`;
  public facebookSocialConnection?: SocialMediaConnection;
  public instagramSocialConnection?: SocialMediaConnection;
  public linkedinSocialConnection?: SocialMediaConnection;
  likes: number = 0;
  followers: number = 0;
  isMetaCompany: boolean = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.brandColor = StringUtils.uuidToHexColor(this.brand.id);
    this.lightColor = StringUtils.adjustBrightness(this.brandColor, 60);
    this.gradient = `linear-gradient(${this.lightColor}, white 30%)`;
    this.setSocialMediaConnection();
    this.generateRandomCounts();
    this.isMetaCompany = this.authService.isMetaCompany();

    // this.hasExpiredConnection();
  }

  brandNameInitial() {
    return this.brand.name.charAt(0).toUpperCase();
  }

  clickCard() {
    this.clickCardEvent.emit();
  }

  generateRandomCounts(): void {
    this.likes = this.getRandomNumber(500, 1000);
    this.followers = this.getRandomNumber(500, 1000);
  }

  getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  setSocialMediaConnection() {
    this.facebookSocialConnection = this.brand.socialMediaConnections.find(
      (smc) => smc.socialNetwork === 'FACEBOOK'
    );
    this.instagramSocialConnection = this.brand.socialMediaConnections.find(
      (smc) => smc.socialNetwork === 'INSTAGRAM'
    );
    this.linkedinSocialConnection = this.brand.socialMediaConnections.find(
      (smc) => smc.socialNetwork === 'LINKEDIN'
    );
  }

  clickTitle(event: Event) {
    event.stopPropagation();
    this.clickTitleEvent.emit();
  }

  public getExpiryTooltip(expiry: ExpiryObject): string {
    if (!expiry) return '';
    return Object.entries(expiry.expiryDatesPerNetwork)
      .map(
        ([network, date]) =>
          `${network} token will expire on ${date}, please reconnect. All posts after this date will be unscheduled!\n`
      )
      .join(', ');
  }

  brandStatus() {
    if (
      this.brand.socialMediaConnections.find(
        (scm) =>
          scm.healthStatus === 'TOKEN_INVALID' ||
          scm.healthStatus === 'TOKEN_EXPIRED'
      )
    ) {
      return '#CA4B4B';
    }

    if (
      this.brand.socialMediaConnections.find(
        (scm) => scm.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE'
      )
    ) {
      return '#fdc65f';
    }

    return '#d1d6e2';
  }

  // private hasExpiredConnection() {
  //   if (
  //     this.instagramSocialConnection &&
  //     !this.instagramSocialConnection.healthStatus
  //   ) {
  //     this.isExpired = true;
  //   }

  //   if (
  //     this.facebookSocialConnection &&
  //     !this.facebookSocialConnection?.healthStatus
  //   ) {
  //     this.isExpired = true;
  //   }

  //   if (
  //     this.linkedinSocialConnection &&
  //     !this.linkedinSocialConnection.healthStatus
  //   ) {
  //     this.isExpired = true;
  //   }
  // }

  formatLink(url: string) {
    return `https://${url}`;
  }
}
