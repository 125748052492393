<div class="container-fluid">
  <div class="spinner-container" [hidden]="!loading">
    <mat-spinner></mat-spinner>
  </div>
  <div
    class="d-flex row flex-nowrap align-items-center mb-4 filter-container-wrapper mt-5"
  >
    <div
      class="d-flex align-items-center filter-container justify-content-between w-100 pb-2"
    >
      <div>
        <h1>Brands</h1>
      </div>
      <div class="d-flex align-items-center">
        <span class="me-2">View</span>
        <button
          type="button"
          class="button-shared-edge btn-left"
          [class.active]="listView"
          (click)="changeToList()"
          [disabled]="!hasAnyBrands"
        >
          <i class="fas fa-bars"></i>
        </button>
        <button
          type="button"
          class="button-shared-edge btn-right"
          [class.active]="gridView"
          (click)="changeToGrid()"
          [disabled]="!hasAnyBrands"
        >
          <i class="fas fa-th"></i>
        </button>
        <span class="ms-4 me-2">Filter by</span>
        <mat-select
          class="select"
          placeholder="Platform"
          [(ngModel)]="selectedPlatforms"
          multiple
          [disabled]="!hasAnyBrands"
        >
          @for(platform of platforms; track platform){
          <mat-option
            [value]="platform"
            class="option"
            (click)="filterByPlatform()"
            >{{ platform | titlecase }}</mat-option
          >
          }
        </mat-select>

        <mat-select
          class="select ms-2"
          placeholder="Industry"
          [(ngModel)]="selectedIndustries"
          multiple
          [disabled]="!hasAnyBrands"
        >
          @for(industry of industries; track industry){
          <mat-option
            [value]="industry"
            class="option"
            (click)="filterByIndustry()"
            >{{ industry }}</mat-option
          >
          }
        </mat-select>
        <button
          mat-flat-button
          color="primary"
          class="ms-4"
          (click)="navigateToCreateBrand()"
        >
          Create Brand
        </button>
      </div>
    </div>
  </div>

  <div
    class="notification-error mb-2"
    *ngIf="expiredTokens.length && gridView && hasAnyBrands"
  >
    <i class="fa-sharp fa-thin fa-circle-info"></i>
    {{ expiredTokens.length }} connection issue{{
      expiredTokens.length > 1 ? "s" : ""
    }}
    found
  </div>

  <div
    class="notification-warning mb-4"
    *ngIf="aboutToExpireTokens.length && gridView && hasAnyBrands"
  >
    <i class="fa-regular fa-triangle-exclamation"></i>
    {{ aboutToExpireTokens.length }} connection{{
      aboutToExpireTokens.length > 1 ? "s" : ""
    }}
    about to expire
  </div>

  <!-- List view -->
  <div
    *ngIf="dataSource.data.length && hasAnyBrands"
    class="table-container p-0 my-0"
    [hidden]="!listView"
  >
    <div style="overflow-x: auto">
      <table
        mat-table
        [hidden]="loading"
        [dataSource]="dataSource"
        class="mat-elevation-z8 custom-table"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="flex-grow-name">Name</th>
          <td mat-cell *matCellDef="let element" class="flex-grow-name">
            <span>
              {{ element.name }}
            </span>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="platform">
        <th mat-header-cell *matHeaderCellDef class="custom-width">
          Social Media Connection
        </th>
        <td mat-cell *matCellDef="let element" class="custom-width">
          <span>
            {{ element.socialNetworks | join }} @if(element.expiry){
            <i
              class="fa-solid fa-exclamation p-2"
              style="font-size: 15px; color: red; font-weight: bolder"
              [matTooltip]="getExpiryTooltip(element.expiry)"
            ></i>
            }</span
          >
        </td>
      </ng-container> -->

        <ng-container matColumnDef="industry">
          <th mat-header-cell *matHeaderCellDef class="custom-width">
            Industry
          </th>
          <td mat-cell *matCellDef="let element" class="custom-width">
            <span> {{ element.industry }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="campaigns">
          <th mat-header-cell *matHeaderCellDef class="custom-width">
            Campaigns
          </th>
          <td mat-cell *matCellDef="let element" class="custom-width">
            <span
              (click)="$event.stopPropagation()"
              style="cursor: pointer"
            >
              <a
                class="link link-underline link-underline-opacity-0"
                (click)="navigateToCampaigns(element, false)"
                title="Social"
              >
                {{ element.socialCampaignsCount }}
              </a>
              /
              <a
                class="link link-underline link-underline-opacity-0"
                (click)="navigateToCampaigns(element, true)"
                title="Paid"
                style="cursor: pointer"
              >
                {{ element.paidCampaignsCount }}
              </a>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef class="custom-width">
            Created
          </th>
          <td mat-cell *matCellDef="let element" class="custom-width">
            <span> {{ element.createdOn | date : "short" }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedOn">
          <th mat-header-cell *matHeaderCellDef class="custom-width">
            Updated
          </th>
          <td mat-cell *matCellDef="let element" class="custom-width">
            <span> {{ element.updatedOn | date : "short" }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="custom-width-actions text-end"
          ></th>
          <td
            mat-cell
            *matCellDef="let element"
            class="custom-width-actions text-end"
          >
            <button
              mat-icon-button
              disableRipple="true"
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="menu"
            >
              <i class="fa-regular fa-ellipsis-vertical fa-2xs"></i>
            </button>
            <mat-menu xPosition="before" #menu="matMenu">
              <button
                mat-menu-item
                [routerLink]="
                  '/main-section/main/brands/edit/' + element.id
                "
              >
                <i class="fal fa-edit"></i>
                Edit
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
    <div
      class="data-alert"
      [hidden]="loading || (dataSource.data && dataSource.data.length)"
    >
      <img src="../../../../../assets/empty_file.svg" />
    </div>
  </div>

  <!--End of List view -->

  <!-- Grid view -->
  <div
    *ngIf="dataSource.data.length && hasAnyBrands"
    class="container-fluid p-0"
    [hidden]="!gridView || loading"
  >
    <div
      class="mx-0 d-flex flex-row justify-content-start flex-wrap p-0"
      style="gap: 20px"
    >
      <brand-card
        class="bc-wrapper"
        *ngFor="let brand of dataSource.data"
        [brand]="brand"
        (clickCardEvent)="navigateToEditBrand(brand)"
        (clickTitleEvent)="navigateToCampaigns(brand)"
      ></brand-card>
      <div class="create-brand-card" (click)="navigateToCreateBrand()">
        <img alt="add brand" src="../../../../../assets/add-brand.svg" />
        <p class="add-brand-card-text px-2">
          Click here to create a new brand to manage campaigns and create posts
        </p>
      </div>
    </div>
  </div>

  <mat-paginator
    #paginator
    [pageSize]="filter.size"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [hidden]="loading || !dataSource.data.length || !hasAnyBrands"
    class="my-4"
  ></mat-paginator>

  <list-empty-state
    *ngIf="!dataSource.data.length && !loading && hasAnyBrands"
  ></list-empty-state>
</div>
<div class="call-to-action" *ngIf="!hasAnyBrands">
  <img src="../../../../../assets/bag.svg" alt="bag" />
  <p class="call-to-action-text">Let’s start and create your first Brand</p>
  <button mat-flat-button color="primary" (click)="navigateToCreateBrand()">
    Create Brand
  </button>
</div>
