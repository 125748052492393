<div class="container pb-4">
  <div class="spinner-container" [hidden]="!loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row d-flex justify-content-center pt-5 mb-2">
    <div class="col-md-12 d-flex align-items-center">
      <h1>
        @if (allowBack) {
        <button mat-icon-button color="primary" aria-label="Go back" (click)="goBack()">
          <i class="fa-solid fa-arrow-left icon-back"></i>
        </button>

        }
        {{ editMode ? "Edit brand" : "Create a brand" }}
      </h1>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="form-header border-grey">
        <h3 class="title">Brand details</h3>
        <button class="arrow-container" (click)="toggleBrandDetailsForm()">
          <img src="../../../../../assets/thin-arrow.svg" alt="arrow" class="arrow"
            [ngClass]="{ 'arrow-up': showBrandDetailsForm }" />
        </button>
      </div>
      <div class="form-wrapper bg-white" [ngClass]="{ 'close-form': !showBrandDetailsForm }">
        <form [formGroup]="brandForm" class="form">
          <div class="row">
            <div class="col-md-6" style="min-width: 550px; margin-left: 25%">
              <div class="d-flex justify-content-between">
                <div class="col-7">
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-2">Name *</label>
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="Name" formControlName="name" maxlength="200" />
                        <mat-error *ngIf="
                            brandForm.controls['name'].invalid &&
                            brandForm.controls['name'].hasError('required')
                          ">
                          Please enter name
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-2">Industry *</label>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="industry">
                          @for(industry of industries; track industry){
                          <mat-option [value]="industry">{{
                            industry
                            }}</mat-option>
                          }
                        </mat-select>
                        <mat-error *ngIf="
                            brandForm.controls['industry'].invalid &&
                            brandForm.controls['industry'].hasError('required')
                          ">
                          Please select industry
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="col-12" style="height: 162px; position: relative">
                    <label class="mb-2">Brand / Product image</label>
                    @if(logo) {
                    <div class="image-container">
                      <img [src]="logo" alt="brand-logo" class="brand-logo" />
                      <div class="overlay"></div>
                      <div class="image-actions">
                        <button class="action action-left" (click)="imageUpload.click()">
                          Change
                        </button>
                        <button class="action action-right" (click)="deleteBrandLogo()">
                          Remove
                        </button>
                        <input type="file" class="form-control mt-2" (change)="onFileSelected($event)" #imageUpload
                          hidden accept="image/*" />
                      </div>
                    </div>
                    } @else {
                    <div class="upload-image-placeholder" (click)="imageUpload.click()">
                      <img src="../../../../../assets/image-upload.svg" alt="image-upload" />
                      <span class="placeholder-label">Upload image</span>
                    </div>
                    <input type="file" class="form-control mt-2" (change)="onFileSelected($event)" #imageUpload hidden
                      accept="image/*" />

                    }
                  </div>
                </div>
              </div>

              <div class="row pb-2">
                <div class="col-12">
                  <label class="mb-2">URL</label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="URL" formControlName="url">
                    <mat-error *ngIf="brandForm.get('url')?.errors?.['pattern']">
                      Please enter a valid URL starting with http:// or https://
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row pb-4">
                <div class="col-12">
                  <label class="mb-2">Details</label>
                  <mat-form-field appearance="outline" class="w-100">
                    <textarea matInput placeholder="Tell us more about your Brand" rows="4"
                      formControlName="details"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-12 text-center mt-2">
                <button mat-flat-button color="primary" (click)="submit()" [disabled]="!brandForm.valid">
                  {{ brandId ? "Update" : "Create Brand" }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="form-header border-grey">
        <h3 class="title" [ngStyle]="{ color: !brandId ? '#b1b1b5' : '' }">
          Connect meta account
        </h3>
        <button class="arrow-container" [disabled]="!brandId" (click)="toggleMetaForm()">
          <img src="../../../../../assets/thin-arrow.svg" alt="arrow" class="arrow"
            [ngClass]="{ 'arrow-up': showMetaForm }" />
        </button>
      </div>
      <div class="connection-form-wrapper bg-white" [ngClass]="{ 'close-form': !showMetaForm }">
        <!-- <div class="col-md-12 pt-5 paid-block" [ngClass]="{ 'active-paid': brandForm.get('paid')?.value && !adAccountConnected }"> -->
        <div class="col-md-12 py-5 paid-block" [ngClass]="{ 'active-paid': true}" [formGroup]="brandForm" *ngIf="!adAccountConnected">
          <div class="row">
            <div class="col-md-6 mx-auto">
              <div class="row mt-3 mb-3 align-items-center connection-block">
                <div class="col-md-8">
                  <h1>Connect your Meta's Ad Manager first.</h1>
                  <p class="color-dark">Once connected, you will be able to schedule and publish your Ads across
                    Facebook and Instagram. This will also help in future brand voice training.</p>
                  <button mat-flat-button color="primary" class="meta-connect" (click)="onMetaConnectClick()">
                    <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22.4992 0.0605469C24.7499 0.0605469 26.8938 1.36484 28.5346 3.81105C30.312 6.46279 31.1841 9.79582 31.2017 13.2404L31.2019 13.347C31.2019 15.4172 30.7939 16.9383 30.0995 18.1401C29.4354 19.2907 28.1473 20.4402 25.9875 20.4631L25.9218 20.4634C24.5544 20.4634 23.3431 20.1665 22.0035 18.9028C20.9738 17.9328 19.7699 16.2098 18.8438 14.6609L16.0888 10.059C16.0103 9.92782 15.9322 9.79851 15.8544 9.67116C15.7759 9.80995 15.6967 9.95032 15.6167 10.0921L14.6489 11.8066C13.113 14.5299 12.4831 15.5917 11.8071 16.5565L11.7298 16.6661L11.6779 16.7389C11.539 16.9331 11.3958 17.1273 11.2402 17.3346C9.53642 19.6035 8.08151 20.4634 6.16621 20.4634C3.91684 20.4634 2.48618 19.4992 1.5944 18.0413L1.56754 17.9969C0.841159 16.7888 0.484375 15.2035 0.484375 13.3972C0.484375 10.1124 1.38595 6.68882 3.10043 4.04499C4.62157 1.70026 6.81675 0.0605469 9.33453 0.0605469C10.7926 0.0605469 12.2422 0.492105 13.7559 1.72803C14.5537 2.37919 15.3769 3.23324 16.262 4.35379C16.9813 3.38227 17.7571 2.46343 18.5917 1.73551C19.8265 0.658747 21.1586 0.0605469 22.4992 0.0605469ZM9.28123 3.39658C7.97042 3.39658 6.85734 4.31631 5.92586 5.72315C4.62322 7.68925 3.82003 10.6072 3.80254 13.422L3.80225 13.5158C3.80225 14.6887 4.05969 15.5892 4.39617 16.134C4.83735 16.8475 5.49537 17.1498 6.16621 17.1498C7.03146 17.1498 7.82301 16.9351 9.34842 14.8254C10.5705 13.1344 12.0104 10.7608 12.9793 9.27283L14.2853 7.26599C13.6477 6.35789 13.0991 5.66682 12.7047 5.24779C11.9154 4.40927 10.9006 3.39662 9.28123 3.39658ZM22.339 2.68942C20.7527 2.68942 19.4762 3.88582 18.0415 6.01904C17.9512 6.15329 17.8606 6.291 17.7696 6.43208C18.2807 7.19067 18.815 8.034 19.3779 8.97159L20.1673 10.2876C22.073 13.4624 23.1572 15.0957 23.7917 15.8658C24.5951 16.8396 25.1614 17.1405 25.8871 17.1496L25.9218 17.1498C27.805 17.1498 28.275 15.4193 28.275 13.4389C28.275 10.6166 27.617 7.48463 26.1674 5.24673C25.1388 3.65936 23.8056 2.68942 22.339 2.68942Z"
                        fill="white" />
                    </svg>
                    Connect
                  </button>
                </div>
                <div class="col-md-4">
                  <img src="../../../../../assets/meta-madsocial.svg" alt="Meta Account Connect" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5" *ngIf="adAccountConnected">
          <div class="row">
            <div class="col-md-6 mx-auto">
              <h3>Ad Manager Account Destination</h3>
              <table class="table mb-5">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Account</th>
                    <th scope="col">Health</th>
                    <th scope="col">Token expiry</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="align-middle">
                    <th scope="row">1</th>
                    <td>
                      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" class="align-middle"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.1953 11.333C22.1953 17.392 17.2836 22.3037 11.2246 22.3037C5.16566 22.3037 0.253906 17.392 0.253906 11.333C0.253906 5.27406 5.16566 0.362305 11.2246 0.362305C17.2836 0.362305 22.1953 5.27406 22.1953 11.333Z"
                          fill="#0081FB" />
                        <path
                          d="M14.0406 7.01855C14.9924 7.01855 15.8991 7.57014 16.593 8.60465C17.3446 9.72607 17.7134 11.1356 17.7209 12.5923L17.721 12.6374C17.721 13.5129 17.5484 14.1562 17.2548 14.6644C16.9739 15.151 16.4292 15.6372 15.5158 15.6468L15.488 15.647C14.9097 15.647 14.3975 15.5214 13.831 14.987C13.3955 14.5768 12.8864 13.8481 12.4947 13.1931L11.3296 11.2469C11.2964 11.1914 11.2634 11.1368 11.2305 11.0829C11.1973 11.1416 11.1638 11.201 11.13 11.2609L10.7207 11.986C10.0711 13.1377 9.80477 13.5867 9.51885 13.9947L9.48617 14.0411L9.46423 14.0719C9.40549 14.154 9.34491 14.2361 9.27914 14.3238C8.5586 15.2833 7.94331 15.647 7.13333 15.647C6.18206 15.647 5.57704 15.2392 5.1999 14.6226L5.18854 14.6039C4.88135 14.093 4.73047 13.4226 4.73047 12.6586C4.73047 11.2695 5.11175 9.82166 5.8368 8.70358C6.4801 7.71199 7.40844 7.01855 8.47321 7.01855C9.08985 7.01855 9.70288 7.20106 10.343 7.72374C10.6804 7.99911 11.0285 8.36029 11.4029 8.83418C11.7071 8.42332 12.0351 8.03474 12.3881 7.7269C12.9103 7.27153 13.4736 7.01855 14.0406 7.01855ZM8.45067 8.42937C7.89633 8.42937 7.42561 8.81833 7.03168 9.41328C6.4808 10.2447 6.14113 11.4788 6.13373 12.6691L6.1336 12.7088C6.1336 13.2048 6.24247 13.5856 6.38478 13.816C6.57135 14.1178 6.84963 14.2456 7.13333 14.2456C7.49924 14.2456 7.83399 14.1548 8.47909 13.2626C8.99589 12.5475 9.60485 11.5437 10.0146 10.9144L10.5669 10.0657C10.2972 9.68171 10.0653 9.38946 9.89848 9.21225C9.56465 8.85764 9.1355 8.42939 8.45067 8.42937ZM13.9728 8.13031C13.302 8.13031 12.7621 8.63627 12.1554 9.53841C12.1172 9.59518 12.0789 9.65343 12.0404 9.71309C12.2566 10.0339 12.4825 10.3905 12.7206 10.7871L13.0544 11.3436C13.8603 12.6862 14.3189 13.3769 14.5872 13.7026C14.9269 14.1144 15.1664 14.2417 15.4733 14.2456L15.488 14.2456C16.2844 14.2456 16.4832 13.5138 16.4832 12.6763C16.4832 11.4827 16.2049 10.1582 15.5919 9.2118C15.1568 8.5405 14.5931 8.13031 13.9728 8.13031Z"
                          fill="white" />
                      </svg>
                      <strong class="ms-2">{{adAccountDetails.name}}</strong> /
                      <span>{{adAccountDetails.actId}}</span>
                    </td>
                    <td>
                      <span
                        class="badge rounded-pill"
                        [ngClass]="{
                          'text-bg-success': adAccountDetails.healthStatus === healthStatus.HEALTHY,
                          'text-bg-warning': adAccountDetails.healthStatus === healthStatus.TOKEN_ABOUT_TO_EXPIRE,
                          'text-bg-secondary': adAccountDetails.healthStatus === healthStatus.DISCONNECTED,
                          'text-bg-danger': adAccountDetails.healthStatus === healthStatus.TOKEN_EXPIRED || adAccountDetails.healthStatus === healthStatus.TOKEN_INVALID
                          }"
                        >
                        <span class="align-baseline">
                          {{ adAccountDetails.healthStatus }}
                        </span>
                      </span>
                    </td>
                    <td>{{ (adAccountDetails.tokenExpiryDate | date : "shortDate") ?? '/' }}</td>
                    <td>
                      <button mat-icon-button disableRipple="true" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu">
                        <i class="fa-regular fa-ellipsis-vertical fa-2xs"></i>
                      </button>
                      <mat-menu xPosition="before" #menu="matMenu">
                        @if (adAccountDetails.healthStatus === healthStatus.HEALTHY || adAccountDetails.healthStatus === healthStatus.TOKEN_ABOUT_TO_EXPIRE) {
                          <button mat-menu-item (click)="disconnectMetaBusinessAccount()">
                            <i class="fal fa-plug-circle-xmark" style="width: 16.5px"></i>
                            Disconnect
                          </button>
                        }
                        @else {
                          <button mat-menu-item (click)="onMetaConnectClick(true)">
                            <i class="fal fa-plug" style="width: 16.5px"></i>
                            Reconnect
                          </button>
                        }
                      </mat-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="form-header border-grey">
        <h3 class="title" [ngStyle]="{ color: !brandId ? '#b1b1b5' : '' }">
          Connect social accounts
        </h3>
        <button class="arrow-container" [disabled]="!brandId" (click)="toggleConnectionsForm()">
          <img src="../../../../../assets/thin-arrow.svg" alt="arrow" class="arrow"
            [ngClass]="{ 'arrow-up': showConnectionsForm }" />
        </button>
      </div>
      <div class="connection-form-wrapper bg-white" [ngClass]="{ 'close-form': !showConnectionsForm }">
        <div class="col-md-9 connection-container py-5">
          <!-- Connected social accounts -->
          <div class="list-label" *ngIf="socialConnectionsDataSource.data.length > 0">
            <p class="mb-2"><b> Connected accounts</b></p>
          </div>
          <!-- Table -->
          <table *ngIf="socialConnectionsDataSource.data.length > 0" mat-table
            [dataSource]="socialConnectionsDataSource.data" class="mat-elevation-z8 scm-table">
            <!-- account -->
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef class="flex-grow-name">
                Account
              </th>
              <td mat-cell *matCellDef="let element" class="flex-grow-name">
                <div class="d-flex align-items-center connection" style="gap: 7px">
                  <img [src]="getImageSrc(element.socialNetwork)" alt="facebook" />
                  <span class="connection-text">{{ element.name }}</span>
                  @if(element.url) {
                  <a [href]="formatLink(element.url)" target="_blank" rel="noopener noreferrer">
                    <img src="../../../../../assets/link-icon.svg" alt="link-icon" />
                  </a>
                  }
                </div>
              </td>
            </ng-container>

            <!-- Health -->
            <ng-container matColumnDef="healthStatus">
              <th mat-header-cell *matHeaderCellDef class="health-width">
                Health
              </th>
              <td mat-cell *matCellDef="let element" class="health-width">
                <span class="health" [ngClass]="{
                    'token-expired':
                      element.healthStatus === 'TOKEN_EXPIRED' ||
                      element.healthStatus === 'TOKEN_INVALID',
                    'token-about-to-expire':
                      element.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE',
                    'disconnected':
                      element.healthStatus === 'DISCONNECTED'
                  }">
                  {{ connectionHealthStatus(element) }}
                </span>
              </td>
            </ng-container>

            <!-- Token expiration -->
            <ng-container matColumnDef="tokenExpiryDate">
              <th mat-header-cell *matHeaderCellDef class="token-expiration-width">
                Token expiry
              </th>
              <td mat-cell *matCellDef="let element" class="token-expiration-width" [ngClass]="{
                  expired:
                    element.healthStatus === 'TOKEN_EXPIRED' ||
                    element.healthStatus === 'TOKEN_INVALID',
                  'about-to-expire':
                    element.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE'
                }">
                <span>{{ element.tokenExpiryDate | date : "shortDate" }}</span>
              </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="custom-width-actions text-end"></th>
              <td mat-cell *matCellDef="let element" class="custom-width-actions text-end">
                <button mat-icon-button disableRipple="true" (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="menu">
                  <i class="fa-regular fa-ellipsis-vertical fa-2xs"></i>
                </button>
                <mat-menu xPosition="before" #menu="matMenu">
                  @if (element.healthStatus === healthStatus.HEALTHY || element.healthStatus === healthStatus.TOKEN_ABOUT_TO_EXPIRE) {
                    <button mat-menu-item (click)="openDisconnectConnectionDialog(element)">
                      <i class="fa-solid fa-plug-circle-xmark mx-1"></i>
                      Disconnect
                    </button>
                  } @else {
                    <button mat-menu-item (click)="reconnectConnection(element)">
                      <i class="fa-solid fa-plug"></i>
                      Reconnect
                    </button>
                  }
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns"></tr>
          </table>
          <!-- Connect social network -->
          <div class="list-label mb-4" *ngIf="
              socialConnectionsDataSource.data.length < 3 &&
              socialConnectionsDataSource.data.length > 0
            ">
            <p class="mb-2"><b> Connect other accounts</b></p>
          </div>
          <div class="row" *ngIf="socialConnectionsDataSource.data.length < 3">
            <div class="d-flex" style="gap: 10px">
              <div class="social-media-card" *ngIf="!findSocialMedialConnection('FACEBOOK')"
                (click)="handleIconClick('FB')">
                <img src="../../../../../assets/facebook.svg" alt="Facebook" style="width: 32px; height: 32px" />
                <span class="label">Connect facebook</span>
              </div>
              <div class="social-media-card" *ngIf="!findSocialMedialConnection('INSTAGRAM')"
                (click)="handleIconClick('IG')">
                <img src="../../../../../assets/instagram.svg" alt="Facebook" style="width: 32px; height: 32px" />
                <span class="label">Connect instagram</span>
              </div>
              <div class="social-media-card" *ngIf="!findSocialMedialConnection('LINKEDIN')"
                (click)="handleIconClick('LI')">
                <img src="../../../../../assets/linkedin.svg" alt="Facebook" style="width: 32px; height: 32px" />
                <span class="label">Connect linkedin</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-header border-grey" *ngIf="!this.isReviewUser">
        <h3 class="title" [ngStyle]="{ color: (!brandId || !isConnectionValid('FACEBOOK')) ? '#b1b1b5' : '' }">
          Brand voice training
          <img src="../../../../../assets/beta.svg" alt="beta"
            [ngClass]="(!brandId || !isConnectionValid('FACEBOOK')) ? 'opacity-50' : ''" />
        </h3>
        <button class="arrow-container" [disabled]="(!brandId || !isConnectionValid('FACEBOOK'))"
          (click)="toggleVoiceForm()">
          <img src="../../../../../assets/thin-arrow.svg" alt="arrow" class="arrow"
            [ngClass]="{ 'arrow-up': showVoiceForm }" />
        </button>
      </div>
      <div
        class="voice-form-wrapper bg-white"
        *ngIf="!this.isReviewUser"
        [ngClass]="{ 'close-form': !showVoiceForm }"
      >
        <div class="row d-flex">
          <div class="col-6 voice-container fw-semibold mt-auto">
            <div class="row">
              <div class="col-2">
                <span>Enable</span>
              </div>
              <div class="col-6">
                <mat-slide-toggle color="primary" [checked]="brandVoice!.enabled"
                  (change)="onBrandVoiceToggleChange($event)"></mat-slide-toggle>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-2">
                <span>Status</span>
              </div>
              <div class="col-6 d-flex align-items-center">
                <div class="dot" [ngClass]="brandVoiceStatusColorMap[currentBrandVoiceStatus] + '-status'"></div>
                <span class="fw-light">{{brandVoiceStatusLabels[currentBrandVoiceStatus]}}</span>
              </div>
            </div>
            <div class="row">
              <span class="description mt-4 fw-light fs-5">
                Brand Voice gives you bonus content variations, specific to the Brand's style and tone, without affecting any current posts.
                <br>
                <i>Brand Voice needs at least 20 previously published posts to start learning about your style and
                  tone</i>.
              </span>
              <p class="hint mt-3"><span>Note: Currently, only Facebook is supported.</span></p>
            </div>
          </div>
          <div class="col-6 voice-image-container py-5">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
