import { ExpiryObject } from '../../modules/main/mad-social/brands/brands-table/brands-table.component';
import { SocialMediaConnection } from './social-media-connection.class';

export class BrandLight {
  id: string = '';
  name: string = '';
  details: string = '';
  industry: string = '';
  deleted: boolean = false;
  campaignsCount: number = 0;
  socialCampaignsCount: number = 0;
  paidCampaignsCount: number = 0;
  assetsCount: number = 0;
  generationConfig: any = null;
  publishConfig: any = null;
  expiry?: ExpiryObject;
  s3LogoCdn: string = '';
  socialMediaConnections: SocialMediaConnection[] = [];
}
