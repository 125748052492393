<h1 mat-dialog-title class="text-center">Select Ad Account</h1>
<mat-dialog-content>
  <p>Select a single ad account from the available options under the chosen Meta business account to manage your ads and paid campaigns.</p>
  <form [formGroup]="form">
    <mat-radio-group formControlName="selectedAdAccount">
      <mat-radio-button
        *ngFor="let account of filteredAccounts"
        [value]="account.id"
        style="display: block; margin-bottom: 10px"
      >
        {{ account.name }}<br><small style="font-size: 0.75em; color: #9a9a9b ">{{ account.id }}</small>
      </mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-button
    mat-flat-button
    color="primary"
    [disabled]="!form.get('selectedAdAccount')?.value"
    (click)="onSelect()"
  >
    Select
  </button>
</div>
