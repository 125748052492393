import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpUtils } from '../utils/http-utils';

@Injectable({
  providedIn: 'root',
})
export class SocialMediaConnectionService {
  constructor(private http: HttpClient) {}

  getAllSocialMediaConnections(requestParams: any): Observable<any> {
    return this.http.get(`${environment.serviceUrl}/social-media-connections`, {
      params: HttpUtils.getHttpParams(requestParams),
    });
  }

  connectFacebook(connectFBPayload: {
    facebookUserToken: string;
    facebookMetaUserId: string;
    facebookAccountId: string;
    brandId: string;
  }): Observable<any> {
    return this.http.post(
      `${environment.serviceUrl}/social-media-connections/connect-facebook`,
      connectFBPayload
    );
  }

  reconnectFacebook(
    smcFBId: string,
    reconnectFBPayload: {
      facebookUserToken: string;
      facebookMetaUserId: string;
      facebookAccountId: string;
    }
  ): Observable<any> {
    return this.http.patch(
      `${environment.serviceUrl}/social-media-connections/reconnect-facebook/${smcFBId}`,
      reconnectFBPayload
    );
  }

  connectInstagram(connectInstagramPayload: {
    instagramUserToken: string;
    instagramMetaUserId: string;
    instagramAccountId: string;
    brandId: string;
  }): Observable<any> {
    return this.http.post(
      `${environment.serviceUrl}/social-media-connections/connect-instagram`,
      connectInstagramPayload
    );
  }

  reconnectInstagram(
    smcINId: string,
    connectInstagramPayload: {
      instagramUserToken: string;
      instagramMetaUserId: string;
      instagramAccountId: string;
    }
  ): Observable<any> {
    return this.http.patch(
      `${environment.serviceUrl}/social-media-connections/reconnect-instagram/${smcINId}`,
      connectInstagramPayload
    );
  }

  connectLinkedin(connectLNPayload: {
    linkedInAccessToken: string;
    brandId: string;
  }): Observable<any> {
    return this.http.post(
      `${environment.serviceUrl}/social-media-connections/connect-linkedin`,
      connectLNPayload
    );
  }

  reconnectLinkedin(
    smcLNId: string,
    connectLNPayload: {
      linkedInAccessToken: string;
    }
  ): Observable<any> {
    return this.http.patch(
      `${environment.serviceUrl}/social-media-connections/reconnect-linkedin/${smcLNId}`,
      connectLNPayload
    );
  }

  disconnectSocialMediaConnection(socialConnectionId: string) {
    return this.http.patch(
      `${environment.serviceUrl}/social-media-connections/${socialConnectionId}/disconnect`, {}
    );
  }
}
