import { SocialMediaConnection } from './social-media-connection.class';

export class Brand {
  public id: string = '';
  public name: string = '';
  public details: string = '';
  public industry: string = '';
  public brandVoiceEnabled: boolean = false;
  public brandVoiceStatus: string = '';
  public s3LogoCdn: string = '';
  public socialMediaConnections: SocialMediaConnection[] = [];
  public url: string = '';
}

export class BrandVoice {
  public id: string = '';
  public brandId: string = '';
  public status: BrandVoiceStatus = BrandVoiceStatus.NONE;
  public error: string = '';
  public lastUpdated: string = '';
  public enabled: boolean = false;
}

export enum BrandVoiceStatus {
  READY = 'READY',
  ERROR = 'ERROR',
  NONE = 'NONE',
  PENDING = 'PENDING',
  DATA_IMPORTING = 'DATA_IMPORTING',
  DATA_IMPORTED = 'DATA_IMPORTED',
  TRAINING = 'TRAINING',
}

export enum BrandVoiceStatusColorMap {
  READY = 'active',
  ERROR = 'error',
  NONE = 'none',
  PENDING = 'pending',
  DATA_IMPORTING = 'pending',
  DATA_IMPORTED = 'pending',
  TRAINING = 'pending',
}

export const BrandVoiceStatusLabels = {
  READY: 'Ready',
  ERROR: 'Error',
  NONE: 'None',
  PENDING: 'Pending',
  DATA_IMPORTING: 'Data Importing',
  DATA_IMPORTED: 'Data Imported',
  TRAINING: 'Training',
};
