import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpUtils } from '../utils/http-utils';
import { MetaBusinessAccountConnectionDto, MetaBusinessAccountConnectionRequest } from '../models/meta.class';
import { Page } from '../models/page.class';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private http: HttpClient) { }

  getBusinessAccount(id: string): Observable<MetaBusinessAccountConnectionDto> {
    return this.http.get<MetaBusinessAccountConnectionDto>(
      `${environment.serviceUrl}/meta-business-connection/${id}`
    );
  }

  listBusinessAccounts(params: {
    name?: string;
    brandIds?: string[];
    companyId?: string;
    healthStatuses?: string[];
    page?: number;
    size?: number;
  }): Observable<Page<MetaBusinessAccountConnectionDto>> {
    return this.http.get<Page<MetaBusinessAccountConnectionDto>>(
      `${environment.serviceUrl}/meta-business-connection$`,
      {
        params: HttpUtils.getHttpParams(params)
      }
    );
  }

  getBusinessAccountForBrand(brandId: string): Observable<MetaBusinessAccountConnectionDto> {
    return this.http.get<MetaBusinessAccountConnectionDto>(
      `${environment.serviceUrl}/meta-business-connection/by-brand/${brandId}`
    );
  }

  connect(request: MetaBusinessAccountConnectionRequest): Observable<MetaBusinessAccountConnectionDto> {
    return this.http.post<MetaBusinessAccountConnectionDto>(
      `${environment.serviceUrl}/meta-business-connection/connect`,
      request
    );
  }

  reconnect(accountId: string, request: MetaBusinessAccountConnectionRequest): Observable<MetaBusinessAccountConnectionDto> {
    return this.http.patch<MetaBusinessAccountConnectionDto>(
      `${environment.serviceUrl}/meta-business-connection/reconnect/${accountId}`,
      request
    );
  }

  disconnect(accountId: string): Observable<MetaBusinessAccountConnectionDto> {
    return this.http.patch<MetaBusinessAccountConnectionDto>(
      `${environment.serviceUrl}/meta-business-connection/disconnect/${accountId}`,
      {}
    );
  }
}
