<h1 mat-dialog-title class="text-center">Select Bussines</h1>
<mat-dialog-content>
  <p>Select the Meta business account you want to connect to our platform to manage paid campaigns and ads.</p>
  <form [formGroup]="form">
    <mat-radio-group formControlName="selectedBusiness">
      <mat-radio-button
        *ngFor="let account of filteredAccounts"
        [value]="account"
        style="display: block; margin-bottom: 10px"
      >
        {{ account.name }}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-button
    mat-flat-button
    color="primary"
    [disabled]="!form.get('selectedBusiness')?.value"
    (click)="onSelect()"
  >
    Select
  </button>
</div>
