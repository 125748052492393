import { BrandLight } from "./brand-light.class";
import { Brand } from "./brand.class";
import { HealthStatus } from "./social-media-connection.class";

export class MetaBusinessAccountConnectionDto {
  public id: string = '';
  public createdOn?: string = '';
  public updatedOn?: string = '';
  public name: string = '';
  public actId: string = '';
  public tokenExpiryDate: string = '';
  public healthStatus: HealthStatus = HealthStatus.DISCONNECTED;
  public brand: BrandLight = new BrandLight();
}

export class MetaBusinessAccountConnectionRequest {
  public name: string = '';
  public accessToken: string = '';
  public actId: string = '';
  public brandId?: string;
}
