import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FacebookSdkService } from '../../../../../shared/services/facebook-sdk.service';
import { BrandService } from '../../../../../shared/services/brand.service';
import { SnackBarService } from '../../../../../core/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Brand, BrandVoice, BrandVoiceStatus, BrandVoiceStatusColorMap, BrandVoiceStatusLabels } from '../../../../../shared/models/brand.class';
import { environment } from '../../../../../../environments/environment';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UnlinkDialogComponent } from '../unlink-dialog/unlink-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { FacebookPageSelectDialogComponent } from '../facebook-page-select-dialog/facebook-page-select-dialog.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HealthStatus, SocialMediaConnection } from '../../../../../shared/models/social-media-connection.class';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SocialMediaConnectionService } from '../../../../../shared/services/social-media-connection.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { AuthService } from '../../../../../shared/services/auth.service';
import { combineLatest, filter, forkJoin, of, switchMap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { FacebookAdAccountSelectDialogComponent } from '../../../campgains/facebook-ad-account-select-dialog/facebook-ad-account-select-dialog.component';
import { FacebookBusinessSelectDialogComponent } from '../../../campgains/facebook-business-select-dialog/facebook-business-select-dialog.component';
import { MetaBusinessAccountConnectionDto, MetaBusinessAccountConnectionRequest } from '../../../../../shared/models/meta.class';
import { MetaService } from '../../../../../shared/services/meta.service';
import { BrandSelectionService } from '../../../../../shared/services/brand-selection.service';

@Component({
  selector: 'app-create-edit-brand',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggle,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  templateUrl: './create-edit-brand.component.html',
  styleUrl: './create-edit-brand.component.scss',
})
export class CreateEditBrandComponent implements OnInit {
  public showBrandDetailsForm: boolean = true;
  public showConnectionsForm: boolean = false;
  public showMetaForm: boolean = false;
  public showVoiceForm: boolean = false;
  public brandForm!: FormGroup;
  public loading: boolean = false;
  public readyToLink: boolean = false;
  public editMode: boolean = false;
  public brandId: string = '';
  public brand: Brand | null = null;
  public brandVoice: BrandVoice | null = new BrandVoice();
  public file: File | null = null;
  public logo: string | null = null;
  public removeLogoFlag: boolean = false;
  public industries: string[] = [];
  public allowBack: boolean | null = false;
  public filter: { socialNetworks: string[] } = { socialNetworks: [] };
  public redirectUrl: string =
    '/main-section/main/brands/brands-table';
  public socialConnectionsDataSource: MatTableDataSource<SocialMediaConnection> =
    new MatTableDataSource<SocialMediaConnection>();
  public readonly brandVoiceStatus = BrandVoiceStatus;
  public readonly brandVoiceStatusLabels = BrandVoiceStatusLabels;
  public readonly brandVoiceStatusColorMap = BrandVoiceStatusColorMap;
  public readonly healthStatus = HealthStatus;
  public currentBrandVoiceStatus: BrandVoiceStatus = BrandVoiceStatus.NONE;
  public isMetaCompany: boolean = false;
  public isReviewUser: boolean = false;
  public adAccountConnected = false;
  public adAccountDetails = new MetaBusinessAccountConnectionDto();
  public columns: string[] = [
    'account',
    'healthStatus',
    'tokenExpiryDate',
    'actions',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private fbService: FacebookSdkService,
    private brandService: BrandService,
    private metaService: MetaService,
    private socialMediaConnectionsService: SocialMediaConnectionService,
    private sbs: SnackBarService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private brandSelectionService: BrandSelectionService // Add this
  ) { }

  ngOnInit() {
    this.determineMode();
    this.setUpBrandForm();
    this.getAllIndustries();
    this.determineMode();

    if (this.editMode) {
      this.getIdFromUrl();
      this.getBrandById();
    }

    if (this.brandId) {
      this.getSocialMediaConnections();
      this.showConnectionsForm = true;
      this.showMetaForm = true;
    }

    this.fbService.loadSdk().then(() => {
      console.log('Facebook SDK loaded.');
    });

    this.isMetaCompany = this.authService.isMetaCompany();
    this.isReviewUser = this.authService.isReviewUser();
  }

  public toggleBrandDetailsForm() {
    this.showBrandDetailsForm = !this.showBrandDetailsForm;
  }

  public toggleConnectionsForm() {
    this.showConnectionsForm = !this.showConnectionsForm;
  }

  public toggleMetaForm() {
    this.showMetaForm = !this.showMetaForm;
  }

  public toggleVoiceForm() {
    this.showVoiceForm = !this.showVoiceForm;
  }


  // Determine if we are in create or edit brand mode based on param from url
  public determineMode(): void {
    if (this.route.snapshot.paramMap.has('id')) {
      this.editMode = true;
    }
    this.allowBack = this.route.snapshot.params['mode'] ? false : true;
  }

  public getIdFromUrl(): void {
    this.route.params.subscribe((params) => {
      this.brandId = params['id'];
    });
  }

  // Function used to fetch campaign by Id, set neccessary profile and campaign info and fetch campaign related posts
  public getBrandById(): void {
    this.loading = true;
    forkJoin([
      this.brandService.getById(this.brandId),
      this.brandService.getBrandVoice(this.brandId),
      this.metaService.getBusinessAccountForBrand(this.brandId),
    ])
      .subscribe({
        next: ([brand, brandVoice, metaAccount]) => {
          this.loading = false;
          this.brand = brand;
          this.brandVoice = brandVoice;
          this.patchFormWithBrandData(brand);
          if (metaAccount) {
            this.adAccountConnected = true;
            this.adAccountDetails = metaAccount;
          }
        },
        error: (error) => {
          this.loading = false;
          this.router.navigate([this.redirectUrl]);
        },
      });
  }

  public getSocialMediaConnections() {
    if (this.brandId) {
      this.socialMediaConnectionsService
        .getAllSocialMediaConnections({ brandIds: [this.brandId] })
        .subscribe({
          next: (response) => {
            this.socialConnectionsDataSource.data = response.content;
            this.isConnectionValid('FACEBOOK') ? this.showVoiceForm = true : null;
          },
          error: (error) => {
            this.sbs.error(
              error?.error?.error?.messages[0] ??
              'Error while fetching social media connections'
            );
          },
        });
    }
  }

  public getAllIndustries(): void {
    this.brandService.getAllIndustries().subscribe({
      next: (response: string[]) => {
        this.industries = response;
      },
      error: (error) => {
        this.sbs.error(
          error?.error?.error?.messages[0] ?? 'Error while fetching industries'
        );
      },
    });
  }

  public loginLinkedIn(): void {
    const linkedinUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.client_id
      }&redirect_uri=${encodeURIComponent(environment.redirect_uri)}&scope=${environment.scope
      }&prompt=login`;

    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      linkedinUrl,
      'LinkedIn',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    window.addEventListener(
      'message',
      (event) => {
        if (event.origin === environment.feUrl) {
          // Ensure this matches your frontend's origin
          const token = event.data.token;

          if (token) {
            this.socialMediaConnectionsService
              .connectLinkedin({
                linkedInAccessToken: token,
                brandId: this.brandId,
              })
              .subscribe({
                next: () => {
                  this.getSocialMediaConnections();
                },
                error: () => { },
              });

            if (popup != null) {
              popup.close();
            }
          }
        } else {
          console.log('Received message from unexpected origin:', event.origin);
        }
      },
      { once: true }
    );
  }

  public onLoginClickFacebook() {

    const metaConfigId = environment.metaConfigId;

    this.fbService
      .login(metaConfigId, true)
      .then((data) => {

        const dialogRef = this.dialog.open(FacebookPageSelectDialogComponent, {
          width: '400px',
          data: { accounts: data.accountsData.data, title: 'facebook' },
        });

        dialogRef.afterClosed().subscribe((selectedPageId) => {
          if (selectedPageId) {
            const metaSocialMediaConnection = {
              facebookUserToken: `${data.loginResponse.authResponse.accessToken}`,
              facebookMetaUserId: `${data.loginResponse.authResponse.userID}`,
              facebookAccountId: selectedPageId,
            };
            this.socialMediaConnectionsService
              .connectFacebook({
                ...metaSocialMediaConnection,
                brandId: this.brandId,
              })
              .subscribe({
                next: () => {
                  this.getSocialMediaConnections();
                },
                error: () => { },
              });
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  public onLoginClickInstagram() {

    const metaInstagramConfigId = environment.metaInstagramConfigId;

    this.fbService
      .login(metaInstagramConfigId, true)
      .then((data) => {
        console.log('Login Data:', data.loginResponse);
        console.log('Accounts Data:', data.accountsData);

        const dialogRef = this.dialog.open(FacebookPageSelectDialogComponent, {
          width: '400px',
          data: { accounts: data.accountsData.data, type: 'instagram' },
        });

        dialogRef.afterClosed().subscribe((selectedPageId) => {
          if (selectedPageId) {
            const metaSocialMediaConnection = {
              instagramUserToken: `${data.loginResponse.authResponse.accessToken}`,
              instagramMetaUserId: `${data.loginResponse.authResponse.userID}`,
              instagramAccountId: selectedPageId,
            };
            this.socialMediaConnectionsService
              .connectInstagram({
                ...metaSocialMediaConnection,
                brandId: this.brandId,
              })
              .subscribe({
                next: () => {
                  this.getSocialMediaConnections();
                },
                error: () => { },
              });
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  public onLogoutClick() {
    this.fbService
      .logout()
      .then(() => {
        console.log('User logged out.');
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
  }

  private patchFormWithBrandData(brand: Brand): void {
    this.brandForm.patchValue({
      name: brand.name,
      industry: brand.industry,
      details: brand.details,
      brandVoiceEnabled: brand.brandVoiceEnabled,
    });
    this.logo = brand.s3LogoCdn;
    this.currentBrandVoiceStatus = this.getBrandVoiceStatus();
  }

  public setUpBrandForm(): void {
    this.brandForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      details: [null],
      industry: ['', [Validators.required]],
      brandVoiceEnabled: [false],
      url: ['', [Validators.pattern(/^(?:(?:https?|ftp|smtp):\/\/)?(?:[a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}.*$/)]]
    });
  }

  public updateBrand(brandVoiceUpdated?: boolean): void {
    this.loading = true;
    const payload = new FormData();

    if (!this.file && this.removeLogoFlag) {
      payload.append(
        'updateRequest',
        new Blob(
          [
            JSON.stringify({
              ...this.brandForm.value,
              removeLogo: this.removeLogoFlag,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );
    } else {
      payload.append(
        'updateRequest',
        new Blob([JSON.stringify(this.brandForm.value)], {
          type: 'application/json',
        })
      );
    }

    if (this.file) {
      payload.append('logo', this.file);
    } else {
      payload.append('logo', '');
    }

    this.brandService.update(this.brandId, payload).subscribe({
      next: (response) => {
        this.loading = false;
        this.sbs.success('Brand updated successfully');
        this.patchFormWithBrandData(response);

        // Notify that brand list has changed
        this.brandSelectionService.notifyBrandListChange();
      },
      error: (error) => {
        if (brandVoiceUpdated) {
          this.brandForm.get('brandVoiceEnabled')?.patchValue(!this.brandForm.get('brandVoiceEnabled')?.value);
          this.brandForm.get('brandVoiceEnabled')?.updateValueAndValidity();
        }
        this.loading = false;
        this.sbs.error(
          error?.error?.error?.messages[0] ?? 'Error while updating brand'
        );
      },
    });
  }

  private createBrand(): void {
    this.loading = true;
    const payload = new FormData();

    payload.append(
      'createRequest',
      new Blob([JSON.stringify(this.brandForm.value)], {
        type: 'application/json',
      })
    );
    if (this.file) {
      payload.append('logo', this.file);
    } else {
      payload.append('logo', '');
    }

    this.brandService.create(payload)
      .pipe(
        filter(response => !!response),
        switchMap(response => combineLatest([of(response), this.brandService.getBrandVoice(response.id)]))
      )
      .subscribe({
        next: ([response, brandVoice]) => {
          this.brandVoice = brandVoice;
          this.loading = false;
          this.sbs.success('Brand created successfully');
          this.brandId = response.id;
          this.showConnectionsForm = true;

          // Notify that brand list has changed
          this.brandSelectionService.notifyBrandListChange();

          // Optionally select the newly created brand
          this.brandSelectionService.selectBrand(response);
        },
        error: (error) => {
          this.loading = false;
          this.sbs.error(
            error?.error?.error?.messages[0] ?? 'Error while creating brand'
          );
        },
      });
  }

  public submit() {
    if (this.brandForm.valid) {
      if (this.brandId) {
        this.updateBrand();
      } else {
        this.createBrand();
      }
    } else {
      console.error('Form is not valid');
    }
  }

  public goBack(): void {
    this.router.navigate(['/main-section/main/brands/brands-table']);
  }

  public handleIconClick(socialNetwork: string) {
    switch (socialNetwork) {
      case 'LI':
        this.loginLinkedIn();

        break;

      case 'FB':
        this.onLoginClickFacebook();

        break;

      case 'IG':
        this.onLoginClickInstagram();

        break;

      default:
        this.sbs.error('No social media associated');
        break;
    }
  }

  public openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '220px',
      width: '800px',
      autoFocus: false,
      data: {
        message:
          'Please confirm that saving the changes will result in some posts being unscheduled',
      },
    });

    return dialogRef.afterClosed();
  }

  public getImageSrc(socialNetwork: string) {
    if (socialNetwork === 'FACEBOOK') {
      return '../../../../../assets/facebook.svg';
    }
    if (socialNetwork === 'INSTAGRAM') {
      return '../../../../../assets/instagram.svg';
    }

    return '../../../../../assets/linkedin.svg';
  }

  public isConnectionValid(socialNetwork: string) {
    return this.socialConnectionsDataSource.data.some(
      (scm) => scm.socialNetwork === socialNetwork && (scm.healthStatus === 'HEALTHY' || scm.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE')
    );
  }

  public findSocialMedialConnection(socialNetwork: string) {
    return this.socialConnectionsDataSource.data.find(
      (scm) => scm.socialNetwork === socialNetwork
    );
  }

  public reconnectConnection(connection: SocialMediaConnection) {
    if (connection.socialNetwork === 'FACEBOOK') {
      this.reconnectFacebook(connection.id);
      return;
    }

    if (connection.socialNetwork === 'INSTAGRAM') {
      this.reconnectInstagram(connection.id);
      return;
    }

    if (connection.socialNetwork === 'LINKEDIN') {
      this.reconnectLinkedin(connection.id);
      return;
    }
  }

  private reconnectFacebook(connectionId: string) {
    this.fbService
      .login(environment.metaConfigId, true)
      .then((data) => {
        console.log('Login Data:', data.loginResponse);
        console.log('Accounts Data:', data.accountsData);

        const dialogRef = this.dialog.open(FacebookPageSelectDialogComponent, {
          width: '400px',
          data: { accounts: data.accountsData.data, title: 'facebook' },
        });

        dialogRef.afterClosed().subscribe((selectedPageId) => {
          if (selectedPageId) {
            const metaSocialMediaConnection = {
              facebookUserToken: `${data.loginResponse.authResponse.accessToken}`,
              facebookMetaUserId: `${data.loginResponse.authResponse.userID}`,
              facebookAccountId: selectedPageId,
            };
            this.socialMediaConnectionsService
              .reconnectFacebook(connectionId, metaSocialMediaConnection)
              .subscribe({
                next: () => {
                  this.getSocialMediaConnections();
                },
                error: (error) => {
                  this.sbs.error(error?.error?.error?.messages[0]);
                },
              });
          }
        });
      })
      .catch((error) => {
        const errorMessage = error?.error?.error?.messages?.[0];

        if (errorMessage) {
          this.sbs.error(errorMessage);
        } else {
          console.warn("Error message is missing or empty.");
        }
      });
  }

  private reconnectInstagram(connectionId: string) {
    this.fbService
      .login(environment.metaInstagramConfigId, true)
      .then((data) => {
        console.log('Login Data:', data.loginResponse);
        console.log('Accounts Data:', data.accountsData);

        const dialogRef = this.dialog.open(FacebookPageSelectDialogComponent, {
          width: '400px',
          data: { accounts: data.accountsData.data, type: 'instagram' },
        });

        dialogRef.afterClosed().subscribe((selectedPageId) => {
          if (selectedPageId) {
            const metaSocialMediaConnection = {
              instagramUserToken: `${data.loginResponse.authResponse.accessToken}`,
              instagramMetaUserId: `${data.loginResponse.authResponse.userID}`,
              instagramAccountId: selectedPageId,
            };
            this.socialMediaConnectionsService
              .reconnectInstagram(connectionId, metaSocialMediaConnection)
              .subscribe({
                next: () => {
                  this.getSocialMediaConnections();
                },
                error: (error) => {
                  this.sbs.error(error?.error?.error?.messages[0]);
                },
              });
          }
        });
      })
      .catch((error) => {
        const errorMessage = error?.error?.error?.messages?.[0];

        if (errorMessage) {
          this.sbs.error(errorMessage);
        } else {
          console.warn("Error message is missing or empty.");
        }
      });
  }

  private reconnectLinkedin(connectionId: string) {
    const linkedinUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.client_id
      }&redirect_uri=${encodeURIComponent(environment.redirect_uri)}&scope=${environment.scope
      }&prompt=login`;

    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      linkedinUrl,
      'LinkedIn',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    window.addEventListener(
      'message',
      (event) => {
        if (event.origin === environment.feUrl) {
          // Ensure this matches your frontend's origin
          const token = event.data.token;

          if (token) {
            this.socialMediaConnectionsService
              .reconnectLinkedin(connectionId, {
                linkedInAccessToken: token,
              })
              .subscribe({
                next: () => {
                  this.getSocialMediaConnections();
                },
                error: (error) => {
                  this.sbs.error(error?.error?.error?.messages[0]);
                },
              });

            if (popup != null) {
              popup.close();
            }
          }
        } else {
          console.log('Received message from unexpected origin:', event.origin);
        }
      },
      { once: true }
    );
  }

  public onMetaConnectClick(reconnect: boolean = false) {
    const metaAdsConfigId = environment.metaAdsConfigId;
    this.fbService
      .loginPaid(metaAdsConfigId, false)
      .then((data) => {
        let accessToken = data.loginResponse.authResponse.accessToken;
        let userID = data.loginResponse.authResponse.userID;

        const dialogRef = this.dialog.open(FacebookBusinessSelectDialogComponent, {
          width: '400px',
          data: { accounts: data.businessData.data, accessToken: accessToken },
        });

        dialogRef.afterClosed().subscribe((selectedBusiness) => {
          if (selectedBusiness) {
            this.fbService.adAccounts(selectedBusiness.id).then((data) => {
              const adAccountDialogRef = this.dialog.open(FacebookAdAccountSelectDialogComponent, {
                width: '400px',
                data: { accounts: data.data, accessToken: accessToken },
              });

              adAccountDialogRef.afterClosed().subscribe((selectedAdAccount) => {
                if (selectedAdAccount) {
                  reconnect ? this.reconnectMetaBusinessAccount(selectedAdAccount, accessToken) : this.connectMetaBusinessAccount(selectedAdAccount, accessToken);
                }
              });
            });
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  public connectMetaBusinessAccount(selectedAdAccount: any, accessToken: string) {
    const request: MetaBusinessAccountConnectionRequest = {
      name: selectedAdAccount.name,
      accessToken,
      actId: selectedAdAccount.id,
      brandId: this.brandId,
    };
    this.metaService.connect(request).subscribe({
      next: (response) => {
        this.adAccountConnected = true;
        this.adAccountDetails = response;
        this.sbs.success('Meta business account connected successfully.');
      },
      error: (error) => {
        this.sbs.error(error?.error?.error?.messages[0]);
      },
    });
  }

  public reconnectMetaBusinessAccount(selectedAdAccount: any, accessToken: string) {
    const request: MetaBusinessAccountConnectionRequest = {
      name: selectedAdAccount.name,
      accessToken,
      actId: selectedAdAccount.id,
      brandId: this.brandId,
    };
    this.metaService.reconnect(this.adAccountDetails.id, request).subscribe({
      next: (response) => {
        this.adAccountConnected = true;
        this.adAccountDetails = response;
        this.sbs.success('Meta business account reconnected successfully.');
      },
      error: (error) => {
        this.sbs.error(error?.error?.error?.messages[0]);
      },
    });
  }

  public disconnectMetaBusinessAccount() {
    this.metaService.disconnect(this.adAccountDetails.id).subscribe({
      next: () => {
        this.sbs.success('Meta business account disconnected successfully.');
        this.adAccountDetails.healthStatus = HealthStatus.DISCONNECTED;
      },
      error: (error) => {
        this.sbs.error(error?.error?.error?.messages[0]);
      },
    });
  }


  private disconnectConnection(connectionId: string) {
    this.socialMediaConnectionsService
      .disconnectSocialMediaConnection(connectionId)
      .subscribe({
        next: () => {
          this.sbs.success('Social media connection deleted successfully.');
          this.getSocialMediaConnections();
        },
        error: (error) => {
          this.sbs.error(error?.error?.error?.messages[0]);
        },
      });
  }

  openDisconnectConnectionDialog(connection: SocialMediaConnection) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      data: {
        message:
          'Please confirm that saving the changes will result in some posts being unscheduled.',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.disconnectConnection(connection.id);
      }
    });
  }

  connectionHealthStatus(connection: SocialMediaConnection) {
    if (connection.healthStatus === 'HEALTHY') {
      return 'Healthy';
    }

    if (connection.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE') {
      return 'About to expire';
    }

    if (connection.healthStatus === 'TOKEN_EXPIRED') {
      return 'Token expired';
    }

    if (connection.healthStatus === 'DISCONNECTED') {
      return 'Disconnected';
    }

    return 'Invalid token';
  }

  onFileSelected(event: any): void {
    const files = event.target.files;

    for (let file of files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.logo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.file = file;
    }
  }

  deleteBrandLogo() {
    this.logo = null;
    this.file = null;
    this.removeLogoFlag = true;
  }

  formatLink(url: string) {
    return `https://${url}`;
  }

  getBrandVoiceStatus(): BrandVoiceStatus {
    return this.brandVoice?.status as BrandVoiceStatus;
  }


  onBrandVoiceToggleChange(event: any) {
    const isEnabled = event.checked;

    const request = isEnabled
      ? this.brandService.enableBrandVoice(this.brandVoice!.id)
      : this.brandService.disableBrandVoice(this.brandVoice!.id);

    request.subscribe({
      next: (brandVoice: BrandVoice) => {
        const message = isEnabled
          ? 'Brand voice enabled successfully.'
          : 'Brand voice disabled successfully.';
        this.sbs.open(message, { duration: 3000 });

        this.currentBrandVoiceStatus = brandVoice.status;
      },
      error: (error) => {
        this.sbs.open(
          error?.error?.error?.messages[0] || 'An error occurred.',
          { duration: 3000 }
        );

        this.currentBrandVoiceStatus = this.brandVoiceStatus.ERROR;
        this.brandVoice!.enabled = false;
      }
    });
  }
}
